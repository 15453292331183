<template>
    <!-- 账号信息 -->
    <div>
        <div class="box">
            <div style="font-weight: bold;font-size: 16px;color: #333333;font-family: Microsoft YaHei, Microsoft YaHei;">基本信息</div>
            <div style="width: 100%;height: 1px;background: #DDDDDD;margin:10px 0 ;"></div>
            <div style="display:flex;font-size: 16px;font-family: Microsoft YaHei, Microsoft YaHei;margin-bottom:10px;">
                <div style="width:100px;color: #666666;">登录名</div>
                <div>{{$store.state.memberInfo.loginName?$store.state.memberInfo.loginName:'--'}}</div>
            </div>
            <div style="display:flex;font-size: 16px;font-family: Microsoft YaHei, Microsoft YaHei;margin-bottom:10px;">
                <div style="width:100px;color: #666666;">姓名</div>
                <div>{{$store.state.memberInfo.contacter?$store.state.memberInfo.contacter:'--'}}</div>
            </div>
            <div style="display:flex;font-size: 16px;font-family: Microsoft YaHei, Microsoft YaHei;margin-bottom:10px;">
                <div style="width:100px;color: #666666;">手机号</div>
                <div>{{$store.state.memberInfo.mobile?maskPhone($store.state.memberInfo.mobile):'--'}}</div>
                <div style="color: #1A9DFF;cursor: pointer;margin-left:5px;" @click="phoneChange">修改</div>
            </div>
            <div style="display:flex;font-size: 16px;font-family: Microsoft YaHei, Microsoft YaHei;margin-bottom:10px;">
                <div style="width:100px;color: #666666;">固定电话</div>
                <div>{{$store.state.memberInfo.tel?$store.state.memberInfo.tel:'--'}}</div>
            </div>
            <div style="display:flex;font-size: 16px;font-family: Microsoft YaHei, Microsoft YaHei;margin-bottom:10px;">
                <div style="width:100px;color: #666666;">联系人邮箱</div>
                <div>{{$store.state.memberInfo.email?$store.state.memberInfo.email:'--'}}</div>
            </div>
            <div style="display:flex;font-size: 16px;font-family: Microsoft YaHei, Microsoft YaHei;margin-bottom:10px;">
                <div style="width:100px;color: #666666;">企业名称</div>
                <div>{{$store.state.memberInfo.companyName?$store.state.memberInfo.companyName:'--'}}</div>
            </div>
            <div style="display:flex;font-size: 16px;font-family: Microsoft YaHei, Microsoft YaHei;margin-bottom:10px;">
                <div style="width:100px;color: #666666;">企业类型</div>
                <div>{{$store.state.memberInfo.shopType?$store.state.memberInfo.shopType:'--'}}</div>
            </div>
            <div style="display:flex;font-size: 16px;font-family: Microsoft YaHei, Microsoft YaHei;margin-bottom:10px;">
                <div style="width:100px;color: #666666;">企业地址</div>
                <div>{{$store.state.memberInfo.address?$store.state.memberInfo.address:'--'}}</div>
            </div>
            <div style="display:flex;font-size: 16px;font-family: Microsoft YaHei, Microsoft YaHei;">
                <div style="width:100px;color: #666666;">企业负责人</div>
                <div>{{$store.state.memberInfo.companyPerson?$store.state.memberInfo.companyPerson:'--'}}</div>
            </div>
            <div style="font-weight: bold;font-size: 16px;color: #333333;font-family: Microsoft YaHei, Microsoft YaHei;margin-top:16px;">资质证照</div>
            <div style="width: 100%;height: 1px;background: #DDDDDD;margin:10px 0 ;"></div>
            <div v-if="isShow&&detail.expireNum+detail.goExpireNum" style="background: #FFF9EE;border-radius: 4px 4px 4px 4px;border: 1px solid #F9EFDB;padding:12px;font-size: 14px;color: #D59619;margin-bottom:20px;display:flex;align-items: center;justify-content: space-between;">
                <div style="display:flex;align-items: center;">
                    <i class="el-icon-warning" style="color:#F7A400;"></i>

                    <div>当前有{{detail.expireNum+detail.goExpireNum}}个资质证件即将过期或已过期，请及时更新证件信息，以免影响您的采购过程</div>
                </div>
                <i class="el-icon-close close" @click="isShow=false"></i>
            </div>
            <el-table :data="tableData" center stripe style="width: 100%" border :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
                <el-table-column prop="name" label="证照名称" width="180">
                </el-table-column>
                <el-table-column prop="date" label="有效期" width="220">
                    <template slot-scope="scope">
                        <div v-if="scope.row.isLong">长期有效</div>
                        <div v-else>{{scope.row.startTime}}~{{scope.row.endTime}}</div>
                    </template>
                </el-table-column>

                <el-table-column label="图片" width="150">
                    <template slot-scope="scope">
                        <el-image  ref="viewer" style="width: 100px; height: 100px" :fit="'cover'" :src="scope.row.img" :preview-src-list="srcList">
                        </el-image>
                        <!-- <el-image style="width: 44px; height: 31px" :src="scope.row.img" :fit="'cover'"></el-image> -->
                    </template>
                </el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <div >{{scope.row.expireNotice?scope.row.expireNotice:'正常'}}</div>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="操作">
                    <template>
                        <el-button type="text" size="small" @click="updateCard">
                            更新
                        </el-button>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
        <changeDialog :phoneVisible="phoneVisible" :cardVisible="cardVisible" @closeDialog="closeDialog"></changeDialog>

    </div>
</template>

<script>
import changeDialog from './components/changeDialog.vue'
export default {
    name: 'AccountInfo',
    components: {
        changeDialog
    },
    data() {
        return {
            tableData: [],
            detail: {},
            isShow: true,
            phoneVisible: false,
            cardVisible: false,
             srcList: []
         
        
        };
    },
    created() {
        this.getCustom()
    },
    mounted() {
     //添加esc键盘事件
    document.addEventListener('keydown', (e) => {
        
      if (e.keyCode === 27) {
console.log(123);

        this.$refs.viewer.closeViewer()
        
      }
    })
  
    },
    methods: {
        deleteRow() { },
        maskPhone(phone) {
            if(phone){
                return phone.substring(0, 3) + '****' + phone.substring(7, 11)
            }
        },
        async getCustom() {
            const res = await this.$api.getCustomInfo({ memberId: 0 })
            console.log(res);
            if (res.data.code == 200) {
                this.detail = res.data.data
                this.tableData = res.data.data.list?res.data.data.list:[]
                this.tableData.forEach(item => {
                    this.srcList.push(item.img)
                })
            }
        },
        phoneChange() {
            this.phoneVisible = true
        },
        updateCard() {
            this.cardVisible = true
        },
        closeDialog() {
            this.phoneVisible = false
            this.cardVisible = false
            this.getCustom()
        },
    }
};
</script>
<style lang="scss" scoped>
.box {
    background: #ffffff;
    padding: 22px 25px 12px;
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei;
    // height: 418px;
    // box-sizing: border-box;
}
.close {
    color: #999999;
    cursor: pointer;
    font-size: 14px;
    &:hover {
        color: #f7a400;
    }
}
</style>