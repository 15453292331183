<template>
    <div>
        <el-dialog title="修改手机号" :visible.sync="phoneVisible" @close="doClose" :before-close="doClose" :close-on-click-modal="false" custom-class="phone-dialog">
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left">
                    <el-form-item label="旧手机号" prop="originallyMobile">
                        <el-input v-model="ruleForm.originallyMobile" placeholder="请输入旧手机号码"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="mobile">
                        <el-input v-model="ruleForm.mobile" placeholder="请输入手机号码"></el-input>
                    </el-form-item>
                    <el-form-item label="短信验证码" prop="code">
                        <div class="verification" style="position:relative;">
                            <el-input v-model="ruleForm.code" placeholder="请输入验证码"></el-input>
                            <div v-if="time" class="verification-btn">{{time}}s后再获取</div>
                            <div v-else class="verification-btn" @click="getCode">短信验证码</div>
                        </div>
                        <!-- <div class="verificationTip">*每天仅可以获取验证码6次,10分钟内仅可以获取3次 </div> -->
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer" style="display:flex;">
                <el-button style="width:50%;" @click="doClose">取 消</el-button>
                <el-button style="width:50%;" type="primary" @click="changePhone">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="上传证件" :visible="cardVisible" custom-class="card-dialog" :before-close="doClose" @close="doClose">
            <div class="dialog-body">

                <div style="display:flex;align-items: center;margin-top:12px;">
                    <el-upload :show-file-list="false" action="" :auto-upload="false" :on-change="onChange" :file-list="fileList">
                        <div class="upload-demo" v-if="fileList.length!=3">
                            <img src="@/assets/images/orderInfo/upload.svg" style=" width: 22px;height: 22px;" alt="">

                        </div>
                    </el-upload>
                    <div class="imgList">
                       <div style=" position: relative;" v-for="(item,index) in fileList" :key="index" >
                         <img style="width:80px;height:80px;margin:0 10px;" :src="item.url" alt="">
                        <i v-if="fileList.length" @click="delImg" class="el-icon-error"></i>
                       </div>
                    </div>
                </div>
                <div style="font-size: 14px;color: #333333;margin:10px 0 ;">有效期至</div>
                <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
                <div style="margin-top:10px;">
                     <el-checkbox v-model="checked">长期有效</el-checkbox>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" style="display:flex;">
                <el-button style="width:50%;" @click="doClose">取 消</el-button>
                <el-button style="width:50%;" type="primary" @click="updateCard">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        phoneVisible: {
            type: Boolean,
            default: false
        },
        cardVisible: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            ruleForm: {
                originallyMobile: '',
                code: '',
                mobile: '',
            },
            checked:true,
            value1:'',
            fileList: [],
            file: '',
            imageUrl: '',
            time: 0,
            timer: null,
            rules: {
                originallyMobile: [
                    { required: true, message: '请输入旧手机号', trigger: 'blur' },
                ],

                mobile: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    {
                        min: 11, max: 11, trigger: 'blur', message: '手机号长度为11位'
                    }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ],

            },
        }
    },
    methods: {
        doClose() {
             this.$refs.ruleForm.resetFields();
            this.$emit('closeDialog', false)

        },
       async changePhone() {
           const res = await this.$api.changePhone(this.ruleForm)
            if (res.data.code == 200){
                this.$message.success('修改成功')
            //    this.$refs.ruleForm.resetFields();
                this.doClose()
            }
        },
        updateCard() {
 
        },
        async onChange(res, fileList) {
            console.log(fileList);
            this.file = res.raw
            const reader = new FileReader();
            reader.onload = e => {
                // 将读取的结果赋值给 imageUrl
                this.imageUrl = e.target.result;
                console.log(this.imageUrl);
            
                this.fileList.push({
                    url: this.imageUrl,
                    name: res.name
                });
            };
            // 读取文件内容
            reader.readAsDataURL(res.raw);

        },
         delImg(){
            this.fileList=[]
        },
       async getCode() {
        if (!this.ruleForm.mobile) return this.$message.error('请输入手机号')
            if (this.time > 0) return
            this.time = 60
            this.timer = setInterval(() => {
                this.time--
                if (this.time <= 0) {
                    clearInterval(this.timer)
                }
            }, 1000);
  const res = await this.$api.getVerificationCode({ mobile: this.ruleForm.mobile })
            if (res.data.code == 200) {
                this.$message.success('发送成功')
            }
 }
       
    }
}
</script>

<style lang="scss">
.phone-dialog {
    width: 470px;
    // height: 360px;
    border-radius: 10px 10px 10px 10px !important;
    .el-dialog__footer {
        text-align: center !important;
    }
    .el-dialog__title {
        font-weight: 600 !important;
        font-size: 22px !important;
        color: #1a1a1a !important;
    }
    .el-form-item__content {
        margin: 0 !important;
    }
}
.verification-btn {
    position: absolute;
    cursor: pointer;
    right: 15px;
    color: #1a9dff;
    top: 40px;
    &:hover {
        color: #337cff;
    }
}
.card-dialog {
    .dialog-body {
        // display: flex;
        // // justify-content: center;
        // flex-direction: column;
        // align-items: center;
        font-size: 14px;
        color: #a0a0a0;
        img {
            width: 172px;
            height: 172px;
        }
    }
}
.imgList {
   display: flex;
    i {
        position: absolute;
        top: 2px;
        right: 1px;
        color: #c2c4c0;
        cursor: pointer;
    }
}
.card-dialog {
    width: 500px;
    // height: 420px;
    border-radius: 10px 10px 10px 10px !important;
    .el-dialog__footer {
        text-align: center !important;
        // margin-top: 60px !important;
    }
    .el-dialog__title {
        font-weight: bold !important;
        font-size: 25px !important;
        color: #1a1a1a !important;
    }
}
.upload-demo {
    width: 80px;
    height: 80px;
    background: #f3f5f5;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: #ebeeee;
    }
}
</style>